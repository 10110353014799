import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Cover from '../components/Cover'
import Cta from '../components/Cta'
import CardList from '../components/CardList'

// Content
import { caseStudyContent } from '../content/caseStudy'
import { ctaContent } from '../content/cta'

const CaseStudyPage = () => {
  const { t } = useTranslation()
  return (
    <Layout headerWhite={true} activePage={'Case Studies'}>
      <Cover content={caseStudyContent(t).cover} />
      <CardList content={caseStudyContent(t).cardList} />
      <Cta content={ctaContent} />
    </Layout>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='Case Study'
    description='Learn how our members use Foursource to find new connections and boost revenues while saving time and money.'
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CaseStudyPage
