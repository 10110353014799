import * as React from 'react'

// Components
import CompanyCard from '../components/CompanyCard'

// Images
import bless from '../assets/images/case-study/logo/bless.png'
import turkishmade from '../assets/images/case-study/logo/turkish-made.png'
import bazif from '../assets/images/case-study/logo/bazif.jpg'
import background from '../assets/images/covers/customer-case-studies-background.svg'
import confi from '../assets/images/case-study/logo/confi.svg'
import seyfeli from '../assets/images/case-study/logo/seyfeli.svg'
import bettina from '../assets/images/case-study/logo/bettina.svg'
import ff from '../assets/images/case-study/logo/ff.svg'
import yellowRiver from '../assets/images/case-study/logo/yellow-river.svg'
import limton from '../assets/images/case-study/logo/limton.svg'
import asbx from '../assets/images/case-study/logo/asbx.jpg'
import araujoIrmaos from '../assets/images/case-study/logo/araujo-irmaos.svg'
import cuteDress from '../assets/images/case-study/logo/cute-dress.jpg'
import nexgen from '../assets/images/case-study/logo/nexgen.svg'
import stuff from '../assets/images/case-study/logo/stuff-limited.jpg'
import campro from '../assets/images/case-study/logo/campro-sports.jpg'
import tricothius from '../assets/images/case-study/logo/tricothius.png'
import ningbo from '../assets/images/case-study/logo/ningbo-new-time.png'
import xiuyu from '../assets/images/case-study/logo/xiuyu-garment-company-logo.jpg'
import abilheira from '../assets/images/case-study/logo/abilheira-cunha-logo.png'
import bucksandblack from '../assets/images/case-study/logo/bucksandblack-logo.png'
import ribble from '../assets/images/case-study/logo/ribbleSports-logo.png'
import msmoda from '../assets/images/case-study/logo/ms-moda-logo.png'
import nilyum from '../assets/images/case-study/logo/nilyum-textile-logo.jpg'
import lovmonkey from '../assets/images/case-study/logo/logo-lovmonkey.jpg'
import nrhermano from '../assets/images/case-study/logo/nr-hermano-espanol-logo.jpg'
import kengvo from '../assets/images/case-study/logo/kengvo-sports-logo.jpg'
import onetopenterprises from '../assets/images/case-study/logo/one-top-enterprises-logo.jpg'
import clothly from '../assets/images/case-study/logo/logo-clothly.jpg'

export const caseStudyContent = (t) => {
  return {
    cover: {
      background: background,
      title: t('Case Studies'),
      text: t(
        'Learn how our members use Foursource to find new connections and boost revenues while saving time and money.'
      ),
      cta: {
        text: t('Join now'),
        url: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
      },
    },
    cardList: {
      component: <CompanyCard />,
      items: [
        {
          logo: { src: clothly, height: 104 },
          name: 'Clothly',
          type: 'Apparel Manufacturer',
          location: 'Netherlands',
          url: '/case-study/clothly',
        },
        {
          logo: { src: onetopenterprises, height: 104 },
          name: 'One Top Enterprises',
          type: 'Apparel Manufacturer',
          location: 'Pakistan',
          url: '/case-study/one-top-enterprises',
        },
        {
          logo: { src: kengvo, height: 104 },
          name: 'Kengvo Sports',
          type: 'Apparel Manufacturer',
          location: 'Pakistan',
          url: '/case-study/kengvo-sports',
        },
        {
          logo: { src: nrhermano, height: 104 },
          name: 'NR HERMANO ESPANOL',
          type: 'Apparel Manufacturer',
          location: 'Spain',
          url: '/case-study/nr-hermano-espanol',
        },
        {
          logo: { src: lovmonkey, height: 104 },
          name: 'Lov &#39;Monkey',
          type: 'Buyer',
          location: 'Romania',
          url: '/case-study/lovmonkey',
        },
        {
          logo: { src: nilyum, height: 104 },
          name: 'Nilyum Tekstil',
          type: 'Apparel Manufacturer',
          location: 'Turkey',
          url: '/case-study/nilyum-naki-tekstil-makine-kimya',
        },
        {
          logo: { src: msmoda, height: 104 },
          name: 'M&S Moda',
          type: 'Apparel Manufacturer',
          location: 'Turkey',
          url: '/case-study/ms-moda',
        },
        {
          logo: { src: ribble, height: 104 },
          name: 'Ribble Sports',
          type: 'Apparel Manufacturer',
          location: 'Pakistan',
          url: '/case-study/ribble-sports',
        },
        {
          logo: { src: bucksandblack, height: 104 },
          name: 'BucksandBlack',
          type: 'Apparel Manufacturer',
          location: 'Pakistan',
          url: '/case-study/bucksandblack',
        },
        {
          logo: { src: xiuyu, height: 104 },
          name: 'Dongguan Xiuyu Fashion Garment Co., Ltd',
          type: 'Apparel Manufacturer',
          location: 'China',
          url: '/case-study/xiuyu-garment',
        },
        {
          logo: { src: abilheira, height: 104 },
          name: 'Abilheira & Cunha - Têxteis, Lda',
          type: 'Apparel Manufacturer',
          location: 'Portugal',
          url: '/case-study/abilheira-cunha-texteis-lda',
        },
        {
          logo: { src: ningbo, height: 104 },
          name: 'Ningbo New Time',
          type: 'Apparel Manufacturer',
          location: 'China',
          url: '/case-study/ningbo-new-time',
        },
        {
          logo: { src: tricothius, height: 104 },
          name: 'Tricothius',
          type: 'Apparel Manufacturer',
          location: 'Portugal',
          url: '/case-study/tricothius',
        },
        {
          logo: { src: bless, height: 104 },
          name: 'Bless Internacional',
          type: 'Apparel Manufacturer',
          location: 'Portugal',
          url: '/case-study/bless-internacional',
        },
        {
          logo: { src: turkishmade, height: 104 },
          name: 'TurkishMade.co',
          type: 'Apparel Manufacturer',
          location: 'Turkey',
          url: '/case-study/turkish-made',
        },
        {
          logo: { src: bazif, height: 104 },
          name: 'Bazif Industries',
          type: 'Apparel Manufacturer',
          location: 'Pakistan',
          url: '/case-study/bazif-industries',
        },
        {
          logo: { src: confi, height: 104 },
          name: 'Con.Fi. Fashion Service',
          type: 'Apparel Manufacturer',
          location: 'Italy',
          url: '/case-study/confi-fashion-service',
        },
        {
          logo: { src: seyfeli, height: 104 },
          name: 'Seyfeli',
          type: 'Apparel Manufacturer',
          location: 'Turkey',
          url: '/case-study/seyfeli',
        },
        {
          logo: { src: bettina, height: 104 },
          name: 'Bettina Lirsch - BL Design',
          type: 'Buyer',
          location: 'Italy & Germany',
          url: '/case-study/bl-design',
        },
        {
          logo: { src: ff, height: 104 },
          name: 'Fateks Kumas',
          type: 'Apparel Manufacturer',
          location: 'Turkey',
          url: '/case-study/fateks-kumas',
        },
        {
          logo: { src: yellowRiver, height: 104 },
          name: 'Yellow River',
          type: 'Apparel Manufacturer',
          location: 'China',
          url: '/case-study/yellow-river',
        },
        {
          logo: { src: limton, height: 104 },
          name: 'Limton Corporation',
          type: 'Apparel Manufacturer',
          location: 'Pakistan',
          url: '/case-study/limton-corporation',
        },
        {
          logo: { src: asbx, height: 104 },
          name: 'ASBX Fashion Group',
          type: 'Apparel Manufacturer',
          location: 'Portugal',
          url: '/case-study/asbx',
        },
        {
          logo: { src: araujoIrmaos, height: 104 },
          name: 'Araújo Irmãos',
          type: 'Apparel Manufacturer',
          location: 'Portugal',
          url: '/case-study/araujoirmaos',
        },
        {
          logo: { src: cuteDress, height: 104 },
          name: 'Cute Dress Industry Limited',
          type: 'Apparel Manufacturer',
          location: 'Bangladesh',
          url: '/case-study/cute-dress-industry',
        },
        {
          logo: { src: nexgen, height: 104 },
          name: 'NexGen',
          type: 'Apparel Manufacturer',
          location: 'Pakistan',
          url: '/case-study/nexgen-sportswear',
        },
        {
          logo: { src: stuff, height: 104 },
          name: 'Stuff Limited',
          type: 'Apparel Manufacturer',
          location: 'Bangladesh',
          url: '/case-study/stuff-limited',
        },
        {
          logo: { src: campro, height: 104 },
          name: 'Campro Sports Gear ',
          type: 'Apparel Manufacturer',
          location: 'Pakistan',
          url: '/case-study/campro-sports',
        }
      ],
    },
  }
}
