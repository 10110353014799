// Base
import * as React from 'react'

// Components
import { Paper, Typography } from '@mui/material'

// Styles
import * as S from './CompanyCard.styles'

const CompanyCard = ({ content }) => {
  return (
    <S.GridItem grid={content?.grid}>
      <Paper
        sx={{
          backgroundColor: 'background.default',
          boxShadow: '0px 8px 16px rgba(171, 190, 209, 0.4)',
          height: '100%',
          py: 3,
          px: 5,
          padding: content?.gutters,
          textAlign: 'center',
          borderRadius: 1.5,
        }}
      >
        {content?.external ? (
          <S.Link href={content?.url} rel='noopener' target='_blank'>
            {content?.logo && (
              <S.Image
                height={content.logo.height}
                width={content.logo.width}
                src={content.logo.src}
                alt={content.name}
                borderRadius={content.logo.borderRadius}
              />
            )}
            {content?.type && (
              <Typography
                variant='h3'
                sx={{
                  mb: 3,
                  height: { sm: '70px' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {content?.type}
              </Typography>
            )}
            {content?.location && <Typography variant='lead'>{content?.location}</Typography>}
            {content?.caption && (
              <Typography component='p' variant='body1Bold'>
                {content?.caption}
              </Typography>
            )}
          </S.Link>
        ) : (
          <S.Link href={content?.url}>
            {content?.logo && (
              <S.Image
                height={content.logo.height}
                width={content.logo.width}
                src={content.logo.src}
                alt={content.name}
                borderRadius={content.logo.borderRadius}
              />
            )}
            {content?.type && (
              <Typography
                variant='h3'
                sx={{
                  mb: 3,
                  height: { sm: '70px' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {content?.type}
              </Typography>
            )}
            {content?.location && <Typography variant='lead'>{content?.location}</Typography>}
            {content?.caption && (
              <Typography component='p' variant='body1Bold'>
                {content?.caption}
              </Typography>
            )}
          </S.Link>
        )}
      </Paper>
    </S.GridItem>
  )
}
export default CompanyCard
