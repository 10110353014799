import { useState, useEffect } from 'react'
import { window } from 'browser-monads'

const SMALL_MOBILE_WIDTH = 400
const MOBILE_WIDTH = 900
const TABLET_WIDTH = 1200
const DESKTOP_WIDTH = 1400

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    smallMobile: undefined,
    mobile: undefined,
    tablet: undefined,
    desktop: undefined,
    bigDesktop: undefined,
    responsive: undefined
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        smallMobile: getScreen(window.innerWidth, SMALL_MOBILE_WIDTH),
        mobile: getScreen(window.innerWidth, MOBILE_WIDTH),
        tablet: getScreen(window.innerWidth, TABLET_WIDTH),
        desktop: getScreen(window.innerWidth, DESKTOP_WIDTH),
        bigDesktop: window.innerWidth > DESKTOP_WIDTH,
        responsive: getScreen(window.innerWidth, TABLET_WIDTH),
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

function getScreen(window, screen) {
  return window < screen
}



export default useWindowSize
