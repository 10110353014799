import styled from 'styled-components'

export const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
  border-radius: ${(props) => props.borderRadius};
  margin-bottom: 16px;
`
export const Link = styled.a`
  display: block;
`
export const GridItem = styled.div.attrs((props) => ({
  className: props.grid === 5 ? 'col-6 col-sm-4 col-md-5ths' : 'col-sm-6 col-lg-4 col-xl-3',
}))`
  padding: 12px !important;
  text-align: center;
`
